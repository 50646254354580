import React, { useEffect, useState } from "react";
import "./sidebar.scss";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuthStore } from "../../store/store";
import checked from "../../Assets/checked.png";
import logo from "../../Assets/SidebarLogo.png";
import chart from "../../Assets/chart.svg";
import micro from "../../Assets/microphone-2.svg";
import music from "../../Assets/musicnote.svg";
import setting from "../../Assets/setting-2.svg";
import plus from "../../Assets/add-circle.svg";
import logoutIcon from "../../Assets/logout.svg";
import Modal from "react-modal";
import { getUser } from "../../helper/userHelper";
import { getS3BucketUrl } from "../../helper/constants";
import axios from "axios";
import { apiSecureHeader } from "../../helper/constants";
import toast from "react-hot-toast";
import profile from "../../Assets/profile.svg";
import Loading from "../Loading";
import Hamburger from "hamburger-react";
import MobileTopBar from "../MobileTopBar";

Modal.setAppElement("#root");

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { auth, logout, setUser } = useAuthStore();
  const [activeTab, setActiveTab] = useState("/");
  const [confirmationIsOpen, setConfirmationIsOpen] = useState(false);
  const [imageModalIsOpen, setImageModalIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  // Monitor changes in auth.user
 
  useEffect(() => {
    const handleResize = () => {
      // Calculate aspect ratio based on window width
      const width = window.innerWidth;

      if (width < 600) {
        setSidebarOpen(false);
      } else if (width >= 600 && width < 1200) {
        setSidebarOpen(true);
      } else {
        setSidebarOpen(true);
      }
    };

    // Set initial aspect ratio on mount
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);
    
    // Cleanup function to remove the event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array means this runs once on mount


  // Fetch user data on initial mount or if user not present in auth
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        let userData;
        const userArrayString = localStorage.getItem("user");
        if (userArrayString) {
          userData = JSON.parse(userArrayString);
        } else {
          userData = await getUser();
          localStorage.setItem("user", JSON.stringify(userData));
        }
        setUser(userData);
      } catch (error) {
        handleLogout(); // Adjust based on your logic
      }
    };

    if (!auth.user) {
      fetchUserData();
    }
  }, [auth.user, setUser]);

  // Update active tab based on location
  useEffect(() => {
    setActiveTab(location.pathname);
  }, [location]);

  const openConfirmation = () => setConfirmationIsOpen(true);
  const closeConfirmation = () => setConfirmationIsOpen(false);
  const openImageModal = () => setImageModalIsOpen(true);
  const closeImageModal = () => {
    setImageModalIsOpen(false);
    setSelectedImage(null);
  };

  const handleImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setSelectedImage(event.target.files[0]);
    }
  };

  const handleImageUpload = async () => {
    if (!selectedImage) return;

    setIsLoading(true);
    const formData = new FormData();
    formData.append("avatar", selectedImage);

    try {
      const response = await axios.post(
        "https://hallelujahofficial.com/api/godcast/user/avatar/update",
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
            "Content-Type": "multipart/form-data",
            secure: apiSecureHeader,
          },
        }
      );

      if (response.status === 200) {
        toast.success("Profile picture updated successfully.");
        const updatedUser = { ...auth.user, avatar: response.data.avatar };
        setUser(updatedUser); // Update user in Zustand store
        // Update local storage
        localStorage.setItem("user", JSON.stringify(updatedUser));
        closeImageModal();
      } else {
        toast.error("Failed to update profile picture.");
      }
    } catch (error) {
      toast.error(
        "An error occurred while updating the profile picture. Please try again later."
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleLogout = async () => {
    closeConfirmation();
    setLoading(true);
    await logout(); // Ensure logout updates Zustand store
    setLoading(false);
    navigate("/auth");
  };

  const handleNavigate = (path) => {
    setActiveTab(path);
    navigate(path);
  };

  const avatar = auth.user ? getS3BucketUrl(auth.user.avatar) : profile;
  const name = auth.user ? auth.user.name : "Hi User";
  const [isSidebarOpen, setSidebarOpen] = useState(true); // Control drawer visibility

  // Toggle sidebar (open/close) on mobile
  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
  };

  // Close sidebar when navigation occurs (useful on mobile)
  useEffect(() => {
    setActiveTab(location.pathname);
    setSidebarOpen(false); // Automatically close sidebar on navigation in mobile view
  }, [location]);

  return (
    <div className={`sidebar-container ${isSidebarOpen ? "open" : ""}`}>
      <div>

      <MobileTopBar isSidebarOpen={isSidebarOpen} setSidebarOpen={(v)=>setSidebarOpen(v)} name={name}
      logo={logo}
      avatar={avatar} logoutIcon={logoutIcon} openImageModal={openImageModal} openConfirmation={openConfirmation}/>

      {/* <button className="hamburger-menu" onClick={toggleSidebar} >
        <Hamburger toggled={isSidebarOpen} toggle={setSidebarOpen} />
      </button> */}
      </div>
      <div className={`sidebar ${isSidebarOpen ? "open" : ""}`}>
        <div className="top">
          <img src={logo} alt="Godcast Logo in sidebar" />
        </div>
        <div className="center">
          <ul>
            <li
              className={activeTab === "/" ? "active" : ""}
              onClick={() => handleNavigate("/")}
            >
              <img src={chart} alt="" className="sidebarIcon" />
              <span>Analytics</span>
            </li>
            <li
              className={activeTab === "/created-episode" ? "active" : ""}
              onClick={() => handleNavigate("/created-episode")}
            >
              <img src={micro} alt="" className="sidebarIcon" />
              <span>Episodes</span>
            </li>
            <li
              className={activeTab === "/published-music" ? "active" : ""}
              onClick={() => handleNavigate("/published-music")}
            >
              <img src={music} alt="" className="sidebarIcon" />
              <span>Music</span>
            </li>
            <li
              className={activeTab === "/settings" ? "active" : ""}
              onClick={() => handleNavigate("/settings")}
            >
              <img src={setting} alt="" className="sidebarIcon" />
              <span>Settings</span>
            </li>
          </ul>
        </div>

        <div className="bottom">
          <ul>
            <li
              className="addButton"
              onClick={() => navigate("/create-episode")}
            >
              <img src={plus} alt="" className="sidebarIcon" />
              <span>Add Podcast</span>
            </li>
            <li
              className="addButton"
              onClick={() => navigate("/publish-music")}
            >
              <img src={plus} alt="" className="sidebarIcon" />
              <span>Add Music</span>
            </li>
          </ul>
        </div>

        <div className="logout">
          <hr style={{ width: "215px" }} />
          <div className="profileBar">
            <div className="ProfileName">
              <div
                className="imgPr"
                onClick={openImageModal}
                style={{ cursor: "pointer" }}
              >
                <img src={avatar} alt="" className="ImgIcon" />
              </div>
              <span>{name}</span>
            </div>
            <img
              src={logoutIcon}
              alt="Logout"
              className="sidebarIcon"
              onClick={openConfirmation}
            />
          </div>
        </div>

        <Modal
          isOpen={confirmationIsOpen}
          onRequestClose={closeConfirmation}
          contentLabel="Confirm Logout"
          style={{
            content: {
              maxHeight: "190px",
              borderRadius: "12px",
            },
          }}
        >
          <img
            src={checked}
            alt=""
            style={{
              width: "48px",
              height: "48px",
              marginBottom: "-35px",
            }}
          />
          <h2>Confirmation</h2>
          <p
            style={{
              textAlign: "center",
              marginTop: "-5px",
              fontSize: "0.85em",
            }}
          >
            Are you sure you want to logout?
          </p>
          <div className="button-group">
            <button
              type="button"
              className="sub-can-button"
              onClick={closeConfirmation}
            >
              No
            </button>
            <button
              type="button"
              className="sub-can-button"
              onClick={handleLogout}
            >
              Yes
            </button>
          </div>
        </Modal>

        <Loading loading={loading} onClose={() => setLoading(false)} />

        <Modal
          isOpen={imageModalIsOpen}
          onRequestClose={closeImageModal}
          contentLabel="Update Profile Picture"
          style={{
            content: {
              maxHeight: "360px",
              maxWidth: "400px",
              borderRadius: "12px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            },
          }}
        >
          <h2 style={{ marginTop: "-40px" }}>Update Profile Picture</h2>
          <img
            src={selectedImage ? URL.createObjectURL(selectedImage) : avatar}
            alt="Current Profile"
            style={{
              width: "160px",
              height: "160px",
              borderRadius: "50%",
              marginBottom: "20px",
              border: "2px solid #67519F",
            }}
          />
          <label htmlFor="fileInput" className="custom-file-input">
            Choose New Picture
          </label>
          <input
            id="fileInput"
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            style={{ display: "none" }}
          />
          <div
            className="button-group"
            style={{ display: "flex", width: "100%" }}
          >
            <button
              type="button"
              className="sub-can-button"
              onClick={closeImageModal}
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={handleImageUpload}
              disabled={isLoading}
              className="sub-can-button"
            >
              {isLoading ? "Uploading..." : "Upload"}
            </button>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default Sidebar;

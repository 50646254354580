import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  IconButton,
  Typography,
} from "@mui/material";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import PauseCircleFilledIcon from "@mui/icons-material/PauseCircleFilled";
import DeleteIcon from "@mui/icons-material/Delete";
import { getS3BucketUrl } from "../../helper/constants";
import musicImg from "../../Assets/musicnote.svg"; // Example Image
import "./RecordItem.scss"; // Your custom SCSS for additional styles

const RecordItem = ({
  record,
  handleClick,
  anchorEl,
  open,
  handleClose,
  handleDelete,
  handleEdit,
  isSegment,
  onPlayPause,
  handleAdd,
  currentlyPlaying,
  onCheckboxChange,
}) => {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0); // Current time for the audio
  const [duration, setDuration] = useState(0); // Total duration of the audio

  const handlePlayPause = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const handleTimeUpdate = () => {
    if (audioRef.current) {
      setCurrentTime(audioRef.current.currentTime);
    }
  };

  const handleSeek = (event) => {
    const seekTime = event.target.value;
    audioRef.current.currentTime = seekTime;
    setCurrentTime(seekTime);
  };

  const handleLoadedMetadata = () => {
    if (audioRef.current) {
      setDuration(audioRef.current.duration);
    }
  };

  const formatDuration = (seconds) => {
    if (!seconds) return "00:00";

    const minutes = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);

    return [minutes.toString().padStart(2, "0"), secs.toString().padStart(2, "0")].join(":");
  };

  useEffect(() => {
    if (audioRef.current) {
      audioRef.current.addEventListener("timeupdate", handleTimeUpdate);
      audioRef.current.addEventListener("loadedmetadata", handleLoadedMetadata);
    }

    return () => {
      if (audioRef.current) {
        audioRef.current.removeEventListener("timeupdate", handleTimeUpdate);
        audioRef.current.removeEventListener("loadedmetadata", handleLoadedMetadata);
      }
    };
  }, []);

  return (
    <div
      className="RecordItem"
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "15px",
        margin: isSegment ? "10px 0" : "15px 0",
        backgroundColor: "#fff",
        borderRadius: "10px",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        flexWrap: "wrap",
        transition: "transform 0.2s, box-shadow 0.2s",
      }}
      onMouseEnter={(e) => {
        e.currentTarget.style.boxShadow = "0px 6px 12px rgba(0, 0, 0, 0.2)";
        e.currentTarget.style.transform = "translateY(-2px)";
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.boxShadow = "0px 4px 10px rgba(0, 0, 0, 0.1)";
        e.currentTarget.style.transform = "translateY(0)";
      }}
    >
      {/* Left Section: Image and Info */}
      <div
        className="RecordItemLeft"
        style={{
          display: "flex",
          alignItems: "center",
          flex: "1 1 50%",
        }}
      >
        <img
          src={musicImg}
          className="RecordImage"
          alt={record.name || "No title"}
          style={{
            cursor: record.file ? "pointer" : "default",
            width: "80px",
            height: "80px",
            borderRadius: "8px",
            transition: "opacity 0.3s",
          }}
        />
        <div className="RecordInfo" style={{ marginLeft: "15px" }}>
          <Typography
            variant="h6"
            sx={{
              fontSize: { xs: "16px", md: "18px" },
              fontWeight: "600",
              color: "#333",
            }}
          >
            {record.name || record.title}
          </Typography>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexWrap: "wrap",
              gap: "10px",
              marginTop: "5px",
            }}
          >
            <Typography
              variant="body2"
              sx={{ fontSize: "14px", color: "#666" }}
            >
              {record.created_at
                ? new Date(record.created_at).toLocaleDateString()
                : new Date(record.updated_at).toLocaleDateString()}
            </Typography>
            {record.duration && (
              <Typography
                variant="body2"
                sx={{
                  fontSize: "14px",
                  color: "#888",
                  fontWeight: 400,
                }}
              >
                {formatDuration(record.duration)}
              </Typography>
            )}
          </div>
        </div>
      </div>

      {/* Right Section: Controls */}
      <div
        className="RecordItemRight"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          flex: "1 1 50%",
          gap: "15px",
        }}
      >
        <audio
          ref={audioRef}
          src={getS3BucketUrl(record.file)}
        ></audio>

        {isSegment && record.active !== 1 && (
          <Typography
            sx={{
              fontSize: { xs: "12px", sm: "14px" },
              color: "gray",
              mr: 2,
            }}
          >
            {record.active === -1
              ? "Processing Background Sound"
              : "Creating Episode"}
          </Typography>
        )}

        {/* Play/Pause Button */}
        <IconButton
          color="primary"
          onClick={handlePlayPause}
          disabled={!record.file && record.active !== 1}
          sx={{
            "&:hover": {
              color: "#4caf50",
            },
          }}
        >
          {isPlaying ? (
            <PauseCircleFilledIcon sx={{ fontSize: 40 }} />
          ) : (
            <PlayCircleFilledIcon sx={{ fontSize: 40 }} />
          )}
        </IconButton>

        {/* Seek Bar */}
        <div style={{ display: "flex", alignItems: "center", gap: "10px", flexGrow: 1 }}>
          <input
            type="range"
            min="0"
            max={duration}
            value={currentTime}
            onChange={handleSeek}
            // style={{ width: "100%" }}
          />
         
        </div>
        <Typography variant="body2">
            {formatDuration(currentTime)} / {formatDuration(duration)}
          </Typography>
        <IconButton onClick={handleDelete}>
          <DeleteIcon
            sx={{
              color: "red",
              "&:hover": {
                color: "#f44336",
              },
            }}
          />
        </IconButton>
      </div>
    </div>
  );
};

RecordItem.propTypes = {
  record: PropTypes.object.isRequired,
  handleClick: PropTypes.func.isRequired,
  anchorEl: PropTypes.any,
  open: PropTypes.bool.isRequired,
};

export default RecordItem;
